<template>
    <div class="ParticipatingUsers">
        <!-- 参与用户 -->
        <div class="searchBtn">
            <el-input placeholder="请输入姓名" v-model="searchInfo.nickname" clearable size="small">
            </el-input>
          
            <el-cascader :options="RolesList"  placeholder="请选择角色" v-model="searchInfo.roleId" size="small" :props="{ value: 'id', label: 'name', emitPath: false}" :show-all-levels="false"></el-cascader>
            <el-cascader :options="OrganizationsList" v-model="searchInfo.departmentId" size="small" placeholder="请选择部门"
                :show-all-levels="false" :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
            </el-cascader>
            <!-- <el-select v-model="searchInfo.isComplete" placeholder="请选择状态" size="small">
                <el-option label="是" :value="true">
                </el-option>
                <el-option label="否" :value="false">
                </el-option>
            </el-select> -->
            <!-- <el-select v-model="searchInfo.organizationId" placeholder="请选择部门" size="small">
                <el-option v-for="item in OrganizationsList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select> -->
            <el-button type="primary" size="small" @click="search">查询</el-button>
            <!-- <el-button type="primary" size="small" @click="exportUser">导出</el-button> -->
        </div>
        <div class="btn">
            <el-button type="primary" size="small" @click="add">新增</el-button>
            <el-button type="primary" size="small" @click="deleteUser">删除</el-button>
        </div>
        <div class="tableList">
            <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection"></el-table-column>
                <el-table-column prop="id" label="编号" width="100">
                </el-table-column>
                <el-table-column prop="nickname" label="姓名" width="180">
                </el-table-column>
                <el-table-column prop="username" label="登录名" width="180">
                </el-table-column>
                <el-table-column prop="roleName" label="角色" width="200">
                </el-table-column>
                <el-table-column prop="departmentName" label="部门" width="320" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="gaugeCount" label="总量表数" width="120">
                </el-table-column>
                <el-table-column prop="completedGaugeCount" label="已完成量表数" width="120">
                </el-table-column>
                <el-table-column prop="resultCount" label="测评结果数" width="120">
                </el-table-column>
                <!-- <el-table-column prop="isComplete" label="是否完成">
                    <template slot-scope="scope">
                        <span v-show="scope.row.isComplete">是</span>
                        <span v-show="!scope.row.isComplete">否</span>
                        </template>
                </el-table-column> -->
                <el-table-column prop="isWarning" label="预警" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.isWarning === 1">是</span>
                        <span v-else-if="scope.row.isWarning === 0">否</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total, prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
        </div>
        <AddUserVue ref="addUser" @getUserList="getUserList"></AddUserVue>
    </div>
</template>

<script>
import { getOrganizationList } from '@/api/system/organization'
import { getRoleList } from '@/api/system/role'
import { getParticipatingUsers, deleteParticipatingUsers } from '@/api/evaluationManagement/plan'
import AddUserVue from './AddUser.vue'
export default {
    data() {
        return {
            OrganizationsList: [], //组织机构列表
            // RolesList: [], // 角色列表
            RolesList: [{id: -1 ,name: '全部'},{id: 1, name: '管理员'}, {id: 2, name:'操作员'}, {id: 3, name: '测评用户'}],
            searchInfo: {
                nickname: null,
                roleId: null,
                departmentId: null,
                type: 1
            },
            tableData: [],
            paging: {
                p: 1,
                ps: 20,
                total: 0
            },
            deleteUserIdList: []
        }
    },
    methods: {
        // 获取参与用户列表
        async getUserList() {
            let info = JSON.parse(JSON.stringify(this.searchInfo));
            if (info.roleId === -1) {
                info.roleId = null;
            }
            if (info.departmentId === -1) {
                info.departmentId = null;
            }
            const res = await getParticipatingUsers({ planId: Number(sessionStorage.getItem('planId')), p: this.paging.p, ps: this.paging.ps, ...info });
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.records;
                this.paging.total = res.data.total;
            }
        },
        // 获取组织机构列表
        async getOrganizationsList() {
            const res = await getOrganizationList()
            console.log('组织机构');
            console.log(res);
            if (res.code === 200) {
                this.OrganizationsList = res.data;
                this.OrganizationsList = [{ id: -1, name: '全部'}, ...this.OrganizationsList]
                // this.deleteNullChildren(this.OrganizationsList)
            }
        },
        // 获取角色列表
        async getRolesList() {
            const res = await getRoleList();
            console.log(res);
            if (res.code === 200) {
                this.RolesList = res.data;
                this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
            }
        },
        // 使用递归取出children为空的数据
        deleteNullChildren(val) {
            console.log(val);
            if (val) {
                val.forEach(item => {
                    if (item.children.length < 1) {
                        delete item.children;
                    }
                    this.deleteNullChildren(item.children)
                });
            }
        },
        // 点击搜索
        search() {
            this.paging.p = 1;
            console.log(this.searchInfo);
            this.getUserList();
        },
        // 导出用户
        exportUser() {
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/plan/excel/export/isComplete?planId=${Number(sessionStorage.getItem('planId'))}`;
        elink.click();
        },
        // 点击新增
        add() {
            // console.log(111)
            this.$refs.addUser.openAdd(this.tableData);
        },
        // 切换分页
        handleCurrentChange(val) {
            this.paging.p = val;
            this.getUserList();
        },
        // 用户选择的数据
        handleSelectionChange(val) {
            console.log(val);
            this.deleteUserIdList = [];
            if (val) {
                val.forEach(item => {
                    this.deleteUserIdList.push(item.id);
                })
            }
        },
        // 点击删除
        async deleteUser() {
            if (this.deleteUserIdList.length > 0) {
                this.$confirm('确认删除该参与用户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const res = await deleteParticipatingUsers(Number(sessionStorage.getItem('planId')), JSON.stringify(this.deleteUserIdList));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            showClose: true,
                            message: '删除成功!'
                        });
                        this.getUserList();
                    } else {
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: '删除失败!'
                        });
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择需要删除的用户！'
                });
            }
        }
    },
    created() {
        this.searchInfo.type = Number(sessionStorage.getItem('planType'));
        this.getOrganizationsList();
        // this.getRolesList();
        this.getUserList();
        
    },
    components: { AddUserVue }
}
</script>

<style lang="scss" scoped>
.ParticipatingUsers {
    // .searchContent {

    //     .el-input,
    //     .el-select,
    //     .el-cascader {
    //         width: 150px;
    //         margin-right: 30px;
    //     }
    // }

    .btn {
        margin-top: 20px;
    }

    .tableList {
        margin-top: 10px;
    }

    .block {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
<template>
    <div class="add">
        <el-dialog :title="'添加用户' + '(' + num + ')'" :visible.sync="dialogVisible" width="50%" :show-close="false">
            <div class="addGauge">
                <div class="searchBtn">
                    <el-input size="small" placeholder="请输入姓名" clearable style="width: 150px" v-model="searchInfo.nickname">
                    </el-input>
                    <el-cascader :options="RolesList"  placeholder="请选择角色" v-model="searchInfo.roleId" size="small" :props="{ value: 'id', label: 'name', emitPath: false}" :show-all-levels="false"></el-cascader>
                    <!-- <el-select size="small" width="150px" v-model="searchInfo.roleId" placeholder="请选择角色">
                        <el-option v-for="item in RolesList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select> -->
                    <el-cascader :options="OrganizationsList" v-model="searchInfo.departmentId" size="small"
                        placeholder="请选择部门" :show-all-levels="false"
                        :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }"></el-cascader>
                    <!-- <el-select size="small" width="150px" v-model="searchInfo.organizationId" placeholder="请选择部门">
                        <el-option v-for="item in OrganizationsList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select> -->
                    <el-button type="primary" size="small" @click="search">查询</el-button>
                </div>
                <div class="tableList">
                    <el-table :data="tableData" border style="width: 100%" size="small" height="500px"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column prop="nickname" label="姓名" width="150">
                        </el-table-column>
                        <el-table-column prop="username" label="登录名" width="200" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="角色" prop="roleName" width="200">
                        </el-table-column>
                        <el-table-column label="部门" prop="departmentName" width="250" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submit" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getOrganizationList } from '@/api/system/organization'
import { getRoleList } from '@/api/system/role'
import { getUsersList } from '@/api/system/users'
import { addParticipatingUsers, getAddUser } from '@/api/evaluationManagement/plan'
export default {
    data() {
        return {
            dialogVisible: false,
            searchInfo: {
                roleId: null,
                departmentId: null,
                nickname: null
            },
            addUserIdList: [],
            num: 0,
            tableData: [],
            OrganizationsList: [], //组织机构列表
            // RolesList: [], // 角色列表
            RolesList: [{id: -1 ,name: '全部'},{id: 1, name: '管理员'}, {id: 2, name:'操作员'}, {id: 3, name: '测评用户'}],
            previewUserList: [], // 用于保存之前已经选择的用户列表
            allUsersList: [] // 用于保存所有用户列表
        }
    },
    methods: {
        // 打开当前模态框
        openAdd(val) {
            this.previewUserList = val;
            this.getUserList();
            this.getOrganizationsList();
            // this.getRolesList();
            this.dialogVisible = true;
        },
        // // 获取用户列表
        // async getUserList() {
        //     let info = JSON.parse(JSON.stringify(this.searchInfo));
        //     console.log(info);
        //     // 判断是否选择的是全部
        //     // departmentId、roleId为-1时，则选择的是全部，需要把值改为null
        //     if(info.departmentId === -1){
        //         info.departmentId = null;
        //     }
        //     if(info.roleId === -1){
        //         info.roleId = null;
        //     }
        //     const res = await getUsersList({ p: 1, ps: 100000, ...info });
        //     console.log(res);
        //     if (res.code === 200) {
        //         this.allUsersList = res.data.userVOList;
        //         // this.tableData = res.data.userVOList;
        //         // let usersList = [];
        //         // 将之前已经选择的去掉
        //        this.tableData = this.allUsersList.filter(a=> !this.previewUserList.some(b=>b.id === a.id))
        //         // 如果筛选的数据是正确的，那么直接把筛选出来的值赋值给tableData
        //         // 再将禁用的用户筛选出去
        //         let arr = this.tableData.filter(item => {
        //             return item.status === 0
        //         });
        //         console.log(arr);
        //         this.tableData = arr;
        //     }
        // },
        async getUserList() {
            let info = JSON.parse(JSON.stringify(this.searchInfo));
                if(info.departmentId === -1){
                info.departmentId = null;
            }
            if(info.roleId === -1){
                info.roleId = null;
            }
            const res = await getAddUser({...info, planId: Number(sessionStorage.getItem('planId'))});
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data;
            }
             //     let info = JSON.parse(JSON.stringify(this.searchInfo));
        //     console.log(info);
        //     // 判断是否选择的是全部
        //     // departmentId、roleId为-1时，则选择的是全部，需要把值改为null
        //     if(info.departmentId === -1){
        //         info.departmentId = null;
        //     }
        //     if(info.roleId === -1){
        //         info.roleId = null;
        //     }
        //     const res = await getUsersList({ p: 1, ps: 100000, ...info });
        //     console.log(res);
        //     if (res.code === 200) {
        //         this.allUsersList = res.data.userVOList;
        },
        // 获取组织机构列表
        async getOrganizationsList() {
            const res = await getOrganizationList()
            console.log('组织机构');
            console.log(res);
            if (res.code === 200) {
                this.OrganizationsList = res.data;
                // 在组织机构列表数组中加入全部选项
                this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
                // 递归删除children为空的数据
                // this.deleteNullChildren(this.OrganizationsList)
            }
        },
        // 使用递归取出children为空的数据
        deleteNullChildren(val) {
            // console.log(val);
            if (val) {
                val.forEach(item => {
                    if (item.children.length < 1) {
                        delete item.children;
                    }
                    this.deleteNullChildren(item.children)
                });
            }
        },
        // 获取角色列表
        async getRolesList() {
            const res = await getRoleList();
            console.log(res);
            if (res.code === 200) {
                this.RolesList = res.data;
                // 在角色数组列表中加入全部选项
                this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
            }
        },
        // 点击搜索
        async search() {
            // console.log(this.searchInfo);
            this.getUserList();
        },
        // 点击选择
        handleSelectionChange(val) {
            console.log(val);
            if (val) {
                this.num = val.length;
                this.addUserIdList = [];
                val.forEach(item => {
                    this.addUserIdList.push({ userId: item.id, planId: Number(sessionStorage.getItem('planId')) });
                })
            }
        },
        // 点击确定
        async submit() {
            const res = await addParticipatingUsers(JSON.stringify(this.addUserIdList));
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    type: 'success',
                    showClose: true,
                    message: '新增成功!'
                });
                // this.getUserList();
                this.$emit('getUserList');
                this.dialogVisible = false;
            } else {
                this.$message({
                    type: 'error',
                    showClose: true,
                    message: '新增失败!'
                });
            }
        }

    },
    created() {

    }

}
</script>

<style lang="scss" scoped>
.add {
    ::v-deep.el-dialog {
        height: 75%;
        overflow-y: scroll;
        // position: relative;
    }

    .addGauge {

        // .searchBtn {
        //     margin-top: 20px;
        //     // display: flex;

        //     .el-input,
        //     .el-select,
        //     .el-cascader {
        //         margin-right: 30px;
        //         width: 150px;
        //         // margin-bottom: 10px;
        //     }

        //     .el-button {
        //         margin-left: 20px;
        //     }
        // }

        .tableList {
            margin-top: 20px;
        }
    }
}
</style>